<template>
  <div class="panel-content-left">
    <template v-if="pricelist">
      <div ref="overlay" class="overlay">
        <div class="card dynamic">
          <div class="header">
            <div>Save as Template</div>
          </div>
          <div class="content mt-3 h-stretch">
            <div class="v-stack h-stretch gap-3">
              <label class="text-left">Name</label>
              <input style="width: unset" v-model="templateName" type="text" placeholder="name" />
            </div>
          </div>
          <div class="footer gap-3 h-space-between mt-3">
            <button @click="dismiss()" class="submit">Cancel</button>
            <button @click="saveAsTemplate()" class="edit">Add</button>
          </div>
        </div>
      </div>

      <div class="panel-right v-space-between">
        <div class="v-stack gap-3 v-start h-stretch">
          <div class="heading-title-1" style="padding-top: 0px">Overview</div>
          <div class="heading-title-2">Fees</div>
          <div class="h-stack h-space-between">
            <div>Production Fee</div>
            <div class="h-stack gap-3">
              <input v-if="edit" v-model="pricelist.productionFeePercent" type="number" min="0" max="100" />
              <div v-if="!edit">{{ pricelist.productionFeePercent }}</div>
              <div>%</div>
            </div>
          </div>
          <div class="h-stack h-space-between">
            <div>Gear Discount</div>
            <div class="h-stack gap-3">
              <input v-if="edit" v-model="pricelist.discountPercent" type="number" min="0" max="100" />
              <div v-if="!edit">{{ pricelist.discountPercent }}</div>
              <div>%</div>
            </div>
          </div>
          <div class="heading-title-2">Summary</div>
          <div class="h-stack h-space-between">
            <div>Raw Price</div>
            <div>{{ format.currency(rawPrice) }} Kč</div>
          </div>
          <div class="separator"></div>
          <div class="h-stack h-space-between">
            <div>Services</div>
            <div>{{ format.currency(servicesPrice) }} Kč</div>
          </div>
          <div class="h-stack h-space-between">
            <div>Gear</div>
            <div>{{ format.currency(gearPrice) }} Kč</div>
          </div>
          <div class="h-stack h-space-between">
            <div>Gear Discount</div>
            <div>{{ format.currency(discount) }} Kč</div>
          </div>
          <div class="h-stack h-space-between">
            <div>Production Fee</div>
            <div>{{ format.currency(productionFee) }} Kč</div>
          </div>
          <div class="separator"></div>
          <div class="h-stack h-space-between">
            <div>Total Price</div>
            <div>{{ format.currency(totalPrice) }} Kč</div>
          </div>
          <div class="h-stack h-space-between">
            <div>VAT {{ pricelist.vat }}%</div>
            <div>{{ format.currency(vatPrice) }} Kč</div>
          </div>
          <div class="h-stack h-space-between">
            <div>Total Price VAT {{ pricelist.vat }}%</div>
            <div>{{ format.currency(totalPriceWithVat) }} Kč</div>
          </div>
        </div>
        <div class="h-stack h-space-between" v-if="edit">
          <button class="edit" @click="cancelEdit()">Cancel</button>
          <button class="submit" @click="submit()">Save</button>
        </div>
        <div class="h-stack h-end" v-if="!edit">
          <button v-if="$store.state.accessLevel > 8" class="edit" @click="startEdit()">
            Edit
          </button>
        </div>
      </div>

      <div class="v-stack gap-3 h-stretch min-width">
        <div class="h-stack h-start gap-3" v-if="!edit">
          <button class="submit" @click="exportPDF('czk')">Export PDF CZK</button>
          <button class="submit" @click="exportPDF('eur')">Export PDF EUR</button>
          <button v-if="!pricelist.template" class="submit" @click="addDialog()">
            Save Template
          </button>
        </div>
        <div v-if="!edit" class="heading-title-1">{{ pricelist.name }}</div>
        <input v-if="edit" class="title" type="text" v-model="pricelist.name" />
        <label>Based on {{ basePricelistName }}</label>
        <TablePricelistItems :listItems="pricelist.items" :editable="edit" :showCount="true"></TablePricelistItems>
      </div>
    </template>
  </div>
</template>

<script>
import TablePricelistItems from "@/components/TablePricelistItems.vue";
import { mapActions } from "vuex";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import ExportPdf from "@/exportpdf.js";
import utils from "@/utils.js";

export default {
  data() {
    return {
      edit: false,
      pricelist: null,
      basePricelistName: "",
      templateName: "",
      format: utils.format,
      settings: null
    };
  },
  props: ["id"],
  components: {
    TablePricelistItems,
  },
  computed: {
    servicesPrice() {
      return utils.pricelist.servicesPrice(this.pricelist);
    },
    gearPrice() {
      return utils.pricelist.gearPrice(this.pricelist);
    },
    rawPrice() {
      return utils.pricelist.rawPrice(this.pricelist);
    },
    discount() {
      return utils.pricelist.discount(this.pricelist);
    },
    productionFee() {
      return utils.pricelist.productionFee(this.pricelist);
    },
    totalPrice() {
      return utils.pricelist.totalPrice(this.pricelist);
    },
    vatPrice() {
      return utils.pricelist.vatPrice(this.pricelist);
    },
    totalPriceWithVat() {
      return utils.pricelist.totalPriceWithVat(this.pricelist);
    },
  },
  methods: {
    ...mapActions([
      "getPricelist",
      "getPricelistItems",
      "updatePricelist",
      "editProject",
      "addPricelist",
      "getSettings"
    ]),
    saveAsTemplate() {
      if (this.templateName.length > 0 && this.pricelist) {
        this.addPricelist({
          name: this.templateName,
          base: false,
          template: true,
          basePricelist: this.pricelist.basePricelist,
          items: this.pricelist.items,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.dismiss();
    },
    addDialog() {
      this.templateName = "";
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    submit() {
      this.pricelist.items = this.pricelist.items.filter(
        (item) => item.count > 0
      );

      this.updatePricelist(this.pricelist)
        .then(() => {
          this.cancelEdit();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startEdit() {
      this.edit = true;
      this.refreshEdit();
    },
    cancelEdit() {
      this.edit = false;
      this.refresh();
    },
    exportPDF(currency) {
      const exporter = new ExportPdf();
      const items = this.pricelist.items.map((item) => {
        const newItem = item.item;
        newItem.count = item.count;
        newItem.price = item.price;
        return newItem;
      });
      let rate = 1
      if (currency == "eur") {
        rate = this.settings.eurConversionRateCZK
      }
      exporter.export(items, this.pricelist, currency, rate);
    },
    refresh() {
      this.getSettings().then((settings) => {
        this.settings = settings
        delete this.settings["_id"]
        delete this.settings["__v"]

        this.getPricelist(this.id)
          .then((list) => {
            this.pricelist = list;

            this.getPricelist(this.pricelist.basePricelist)
              .then((pricelist) => {
                this.basePricelistName = pricelist.name;
              })
              .catch((error) => {
                console.log(console.log(error));
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch((error) => {
        console.log(error)
      })
    },
    refreshEdit() {
      this.getPricelist(this.pricelist.basePricelist)
        .then((pricelist) => {
          const items = pricelist.items;

          this.getPricelist(this.id)
            .then((list) => {
              this.pricelist = list;
              const allItems = items;
              for (const pricelistItem of list.items) {
                for (const item of allItems) {
                  if (pricelistItem._id == item._id) {
                    item.price = pricelistItem.price;
                    item.count = pricelistItem.count;
                  }
                }
              }
              this.pricelist.items = allItems;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/panes.scss";

.panel {
  @extend .pane-bottom;
  position: fixed;
  top: 72px;
  right: 0;
  height: calc(100vh - 120px);
  width: 400px;
  overflow-y: auto;
}

.min-width {
  min-width: 780px;
}

.table-panel {
  width: calc(100vw - 514px);
  overflow-x: auto;
}

.h-stack div {
  align-self: center;
}

input {
  width: 80px;
}

input.title {
  width: unset;
}
</style>
